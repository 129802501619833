#gf-root .chart-container {
  margin: 10px auto 0;
  height: 100% !important;
}
#gf-root .cardviewer-chart-viz-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#gf-root .cardviewer-chart-viz-container.textVizType {
  height: auto;
  background: #fff;
}
#gf-root .cardviewer-chart-viz-container .fr-view,
#gf-root .cardviewer-container .fr-view {
  text-align: center;
}
#gf-root .chart-div {
  position: relative;
  float: left;
  width: 80% !important;
}
#gf-root .chart-wrapper {
  width: 100%;
  height: 100%;
}
#gf-root .auto-disabled-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#gf-root .pinned-map-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: flex-end;
}
#gf-root .pinned-map-container .pinned-map-legend-colorby-height {
  height: 300px;
}
#gf-root .pinned-map-container .pinned-map-legend-container {
  padding-bottom: 20px;
  width: 180px;
}
#gf-root .cardbuilder .chart-container,
#gf-root .cardPreview .chart-container {
  height: 100% !important;
}

.printTitle {
  text-align: center;
}

.chart-height {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.breadcrumb {
  color: #1565c0;
  cursor: pointer;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
}
.breadcrumb.drillthrough-crumb {
  color: #c75000;
}

.breadcrumb-divider {
  color: #ccc;
}

.aboutyToPrintWidth {
  width: 250px;
}

.normalPageWidth {
  width: 383px;
}

.big-number-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.big-number-container .big-number-column-name {
  margin-top: 25%;
  text-align: center;
}
.big-number-container .big-number-value {
  text-align: center;
}

.tree-table-subtitle {
  font-size: 12px;
}

.tree-table-not-found {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #eb0000;
  font-size: 50px;
}

@media print {
  .treeTablePrint {
    height: 100% !important;
  }
}
.pinned-map-has-drillthrough circle {
  cursor: pointer;
}

.filled-map-has-drillthrough g[focusable=true] {
  cursor: pointer;
}

#react-table-container,
#react-table-pivot-container {
  height: 100%;
  overflow-wrap: break-word;
}
#react-table-container .react-table,
#react-table-pivot-container .react-table {
  overflow: hidden;
  font-size: 14px;
  height: 100% !important;
  font-family: "Roboto", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 1px solid rgba(0, 0, 0, 0.05) !important;
}
#react-table-container .greenfieldTableHeaderAlignLeft,
#react-table-pivot-container .greenfieldTableHeaderAlignLeft {
  text-align: left;
  justify-content: flex-start !important;
}
#react-table-container .greenfieldTableHeaderAlignCenter,
#react-table-pivot-container .greenfieldTableHeaderAlignCenter {
  justify-content: center !important;
  text-align: center;
}
#react-table-container .greenfieldTableHeaderAlignRight,
#react-table-pivot-container .greenfieldTableHeaderAlignRight {
  text-align: right;
  justify-content: flex-end !important;
}
#react-table-container .greenfieldTableHeaderAlignJustify,
#react-table-pivot-container .greenfieldTableHeaderAlignJustify {
  text-align: justify;
  display: inline-block !important;
}
#react-table-container .greenfieldTableHeaderAlignVerticalTop,
#react-table-pivot-container .greenfieldTableHeaderAlignVerticalTop {
  align-items: flex-start !important;
  padding: 1px 5px 5px 5px;
}
#react-table-container .greenfieldTableHeaderAlignVerticalMiddle,
#react-table-pivot-container .greenfieldTableHeaderAlignVerticalMiddle {
  align-items: center !important;
  text-align: center;
}
#react-table-container .greenfieldTableHeaderAlignVerticalBottom,
#react-table-pivot-container .greenfieldTableHeaderAlignVerticalBottom {
  align-items: flex-end !important;
  padding: 5px 5px 1px 5px;
}
#react-table-container .greenfieldTableBodyAlignLeft,
#react-table-pivot-container .greenfieldTableBodyAlignLeft {
  display: flex;
  text-align: left;
  justify-content: left;
}
#react-table-container .greenfieldTableBodyAlignCenter,
#react-table-pivot-container .greenfieldTableBodyAlignCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}
#react-table-container .greenfieldTableBodyAlignRight,
#react-table-pivot-container .greenfieldTableBodyAlignRight {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}
#react-table-container .greenfieldTableBodyAlignJustify,
#react-table-pivot-container .greenfieldTableBodyAlignJustify {
  display: flex;
  justify-content: normal;
}
#react-table-container .greenfieldTableBodyAlignVerticalTop,
#react-table-pivot-container .greenfieldTableBodyAlignVerticalTop {
  display: flex;
  align-items: flex-start !important;
  padding: 1px 5px 5px 5px;
}
#react-table-container .greenfieldTableBodyAlignVerticalMiddle,
#react-table-pivot-container .greenfieldTableBodyAlignVerticalMiddle {
  display: flex;
  align-items: center !important;
}
#react-table-container .greenfieldTableBodyAlignVerticalBottom,
#react-table-pivot-container .greenfieldTableBodyAlignVerticalBottom {
  display: flex;
  align-items: flex-end !important;
  padding: 5px 5px 1px 5px;
}
#react-table-container .drillthrough-link,
#react-table-pivot-container .drillthrough-link {
  cursor: pointer;
}
#react-table-container .drillable-link,
#react-table-pivot-container .drillable-link {
  color: #1565c0;
  cursor: pointer;
}
#react-table-container .ReactTable.pivotColGroupingsHead-wrap .rt-thead .rt-th > div,
#react-table-container .ReactTable.pivotColGroupingsHead-wrap .rt-thead.-headerGroups .rt-th,
#react-table-pivot-container .ReactTable.pivotColGroupingsHead-wrap .rt-thead .rt-th > div,
#react-table-pivot-container .ReactTable.pivotColGroupingsHead-wrap .rt-thead.-headerGroups .rt-th {
  white-space: initial;
}
#react-table-container .rt-th,
#react-table-pivot-container .rt-th {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}
#react-table-container .rt-td,
#react-table-pivot-container .rt-td {
  vertical-align: middle;
  font-size: inherit;
  background-color: inherit;
}
#react-table-container .rt-thead .rt-tr,
#react-table-pivot-container .rt-thead .rt-tr {
  padding-right: 13px;
}
#react-table-container .rt-tbody .rt-tr,
#react-table-pivot-container .rt-tbody .rt-tr {
  min-height: 28px;
  background-color: inherit;
}
#react-table-container .rt-tbody .rt-tr:hover,
#react-table-pivot-container .rt-tbody .rt-tr:hover {
  background-color: #f7f7f7 !important;
}
#react-table-container .rt-tfoot .rt-tr,
#react-table-pivot-container .rt-tfoot .rt-tr {
  min-height: 48px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
#react-table-container .rt-tfoot .rt-tr .drillable-link,
#react-table-pivot-container .rt-tfoot .rt-tr .drillable-link {
  color: #000;
  cursor: default;
}
#react-table-container .greenfieldTableColumnHeader,
#react-table-pivot-container .greenfieldTableColumnHeader {
  min-height: 30px;
  padding-right: 0px !important;
}
#react-table-container .text-center,
#react-table-pivot-container .text-center {
  justify-content: space-around;
}
#react-table-container .text-right,
#react-table-pivot-container .text-right {
  text-align: right;
  justify-content: flex-end;
  padding-right: 16px;
}
#react-table-container .ReactTable .rt-tfoot,
#react-table-pivot-container .ReactTable .rt-tfoot {
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 -10px 10px -10px rgba(0, 0, 0, 0.1) inset !important;
}
#react-table-container .ReactTable .rt-thead.-header,
#react-table-pivot-container .ReactTable .rt-thead.-header {
  flex: none;
}
#react-table-container .column-desc-icon-btn,
#react-table-pivot-container .column-desc-icon-btn {
  padding: 0px;
  vertical-align: super;
}
#react-table-container .column-desc-icon,
#react-table-pivot-container .column-desc-icon {
  font-size: x-small;
  color: #51a255;
}
#react-table-container .rt-tr[total_expand_icon="0"] > .rt-expandable,
#react-table-pivot-container .rt-tr[total_expand_icon="0"] > .rt-expandable {
  visibility: hidden;
}

.ag-root-wrapper-body .ag-layout-normal {
  height: 100%;
}

.ag-theme-balham .ag-header-group-cell-label {
  justify-content: center;
}

.ag-header-group-cell .ag-react-container {
  width: 100%;
}

.ag-grid-table-container {
  flex: 1 1 auto;
}
.ag-grid-table-container .drillthrough-link {
  cursor: pointer;
}
.ag-grid-table-container .drillable-link {
  color: #1565c0;
  cursor: pointer;
}

.ag-cell .ag-cell-wrapper {
  overflow: hidden;
  align-items: center;
}

.top,
.top .ag-cell-wrapper {
  align-items: flex-start;
}

.middle,
.middle .ag-cell-wrapper {
  align-items: center;
}

.bottom,
.bottom .ag-cell-wrapper {
  align-items: flex-end;
}

.left {
  justify-content: flex-start;
  text-align: start;
}

.center {
  justify-content: center;
  text-align: center;
}

.right {
  justify-content: flex-end;
  text-align: end;
}

/* Applies styling to custom header container */
/****TODO: UNCOMMENT FOR COLUMN SWAP COMPONENT****/
.column-swap .column-swap-content {
  display: flex;
  flex-direction: column;
}
.column-swap .column-swap-chips {
  width: 250px;
  margin: 5px;
}